import React from "react"
import { setImageParameters } from "../../utils/imageResize"

interface PackagingContentProps {
  isOpen: boolean
  title: string
  description: string
  buttonText: string
  buttonLink: string
  image: any
}

const PackagingContent: React.FC<PackagingContentProps> = props => {
  const imgUrl = setImageParameters(props.image.url, { width: 1210, height: 630, mode: "crop" })

  if (!props.isOpen) {
    return null
  }
  return (
    <>
      <div className="packaging__body">
        <div className="packaging__title">
          <h3>{props.title}</h3>
        </div>
        <div className="packaging__copy" dangerouslySetInnerHTML={{ __html: props.description }}></div>
        {props.buttonLink ? (
          <div className="packaging__cta">
            <a href={"/" + props.buttonLink} className="cta cta--secondary">
              <span>{props.buttonText}</span>
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="packaging__media">
        <img src={imgUrl} className="packaging__mediacontent" alt="" />
      </div>
    </>
  )
}

export default PackagingContent
