import React from "react"
import Tile from "./tile"

interface DiscoverApplicationsProps {
  img: any
  quote: string
  title: string
  tileData: any
}

const DiscoverApplications: React.FC<DiscoverApplicationsProps> = props => {
  return (
    <div className="discover discover--bgtertiary">
      <div className="discover__container">
        <div className="discover__visual">
          <img src={props.img.url} alt="" />
          <div className="discover__quote" dangerouslySetInnerHTML={{ __html: props.quote }}></div>
        </div>
        <div className="discover__tiles tile-list tile-list--2col">
          <div className="discover__title">
            <div
              dangerouslySetInnerHTML={{
                __html: props.title
                  .replace(/<span style="text-decoration: underline;">/g, '<span class="underline underline--application">')
                  .replace(/<\/span>/g, "</span>"),
              }}
            ></div>
          </div>
          <div className="tile-list__tiles">
            {props.tileData.map((t, index) => (
              <Tile key={index} icon={t.content.general.icon} title={t.content.general.title} text={t.content.general.description}></Tile>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiscoverApplications
