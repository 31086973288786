import React from "react"
import ListItem from "../../shared/list-item"

interface IntroProps {
  title: string
  firstParagraph: string
  secondParagraph: string
  blockTitle?: string
  childItems: any
}

const Intro: React.FC<IntroProps> = props => {
  return (
    <div className="intro">
      <div className="intro__body">
        <div className="intro__title">
          <div
            dangerouslySetInnerHTML={{
              __html: props.title
                .replace(/<span style="text-decoration: underline;">/g, '<span class="underline">')
                .replace(/<\/span>/g, "</span>"),
            }}
          ></div>
          <div dangerouslySetInnerHTML={{ __html: props.firstParagraph }}></div>
          <div dangerouslySetInnerHTML={{ __html: props.secondParagraph }}></div>
        </div>
      </div>

      {props.blockTitle ? (
        <div className="intro__sidebar">
          <div className="tile tile--darkbackground">
            <div className="tile__body">
              <div className="tile__title">
                <h3>{props.blockTitle}</h3>
              </div>
              <div className="tile__text">
                <ul className="list">
                  {props.childItems.map((c, index) => (
                    <ListItem key={index} title={c.content.general.title}></ListItem>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default Intro
