import React from "react"

interface TileSliderItemProps {
  title: string
  description: string
  buttonText: string
  path: string
}

const TileSliderItem: React.FC<TileSliderItemProps> = props => {
  return (
    <a href={props.path ? "/" + props.path : null} className="tile tile__link tile--shadow">
      <div className="tile__body">
        <div className="tile__title" dangerouslySetInnerHTML={{ __html: props.title }}></div>
        <div className="tile__text">
          <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </div>
        <div className="link link--witharrow">{props.path ? <span>{props.buttonText}</span> : null}</div>
      </div>
    </a>
  )
}

export default TileSliderItem
