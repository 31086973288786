import React from "react"
import DiagramTiles from "./diagram-tiles"
import DiagramTile from "./diagram-tile"
import DiagramTitle from "./diagram-title"
import DiagramTilesbottom from "./diagram-tilesbottom"

interface DiagramProps {
  diagramTiles: any
  diagramTile: any
  diagramTitle: any
  diagramTileBottom: any
}

const Diagram: React.FC<DiagramProps> = props => {
  return (
    <div className="diagram diagram--erp">
      <DiagramTiles
        headTitle={props.diagramTiles.map(d => d.content.general.blockTitle1)}
        lowerTitle={props.diagramTiles.map(d => d.content.general.blockLowerTitle1)}
        secondHeadTitle={props.diagramTiles.map(d => d.content.general.blockTitle2)}
        secondLowerTitle={props.diagramTiles.map(d => d.content.general.blockLowerTitle2)}
        thirdLowerTitle={props.diagramTiles.map(d => d.content.general.blockLowerTitle3)}
        listItems={props.diagramTiles.map(d => d.childItems)}
      ></DiagramTiles>
      <DiagramTile
        title={props.diagramTile.map(d => d.content.general.title)}
        listItems={props.diagramTile.map(d => d.childItems)}
      ></DiagramTile>
      <DiagramTitle title={props.diagramTitle.map(d => d.content.general.title)}></DiagramTitle>
      <DiagramTilesbottom items={props.diagramTileBottom}></DiagramTilesbottom>
    </div>
  )
}

export default Diagram
