import React, { useRef, useState, useEffect } from "react"
import TileSliderItem from "./tile-slider-item"
import Carousel from "react-elastic-carousel"
import { isMobile } from "react-device-detect"

interface TileSliderProps {
  title: string
  childItems: any
}

const TileSlider: React.FC<TileSliderProps> = props => {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  const carouselRef = useRef(null)

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 992, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ]

  return (
    <section>
      <div className="tile-slider">
        <div className="tile-list tile-list--4col">
          <div className="tile-list__container">
            <div
              className="tile-list__title"
              dangerouslySetInnerHTML={{
                __html: props.title
                  .toString()
                  .replace(/<strong>/g, '<span class="bold">')
                  .replace(/<\/strong>/g, "</span>"),
              }}
            ></div>
            <div className="tile-list__tiles">
              <Carousel
                ref={carouselRef}
                pagination={false}
                showArrows={false}
                itemPadding={[0, 0, 0, 0]}
                breakPoints={breakPoints}
                enableSwipe={mobile}
              >
                {props.childItems.map(c => (
                  <TileSliderItem
                    key={c.id}
                    title={c.content.general.title}
                    description={c.content.general.description}
                    buttonText={c.content.general.buttonText}
                    path={c.content.general.path}
                  ></TileSliderItem>
                ))}
              </Carousel>
            </div>

            {props.childItems.length <= 4 && !isMobile ? (
              <></>
            ) : (
              <div className="tile-slider__controls">
                <a
                  onClick={() => carouselRef && carouselRef.current && carouselRef.current.slidePrev()}
                  className="tile-slider__arrow tile-slider__arrow--prev"
                >
                  <span></span>
                </a>
                <a
                  onClick={() => carouselRef && carouselRef.current && carouselRef.current.slideNext()}
                  className="tile-slider__arrow tile-slider__arrow--next"
                >
                  <span></span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TileSlider
