import React from "react"
import Accordion from "../accordion/accordion"

interface VisualWithTextProps {
  title: string
  firstParagraph: string
  secondParagraph: string
  accordionData: any
  image: any
}

const VisualWithText: React.FC<VisualWithTextProps> = props => {
  return (
    <section>
      <div className="visualwithtext">
        <div className="visualwithtext__visual">
          <img src={props.image.url} alt="Circle graph of Tide funcionalities" />
        </div>
        <div className="visualwithtext__text">
          <div
            dangerouslySetInnerHTML={{
              __html: props.title
                .replace(/<span style="text-decoration: underline;">/g, '<span class="underline">')
                .replace(/<\/span>/g, "</span>"),
            }}
          ></div>
          <div dangerouslySetInnerHTML={{ __html: props.firstParagraph }}></div>
          <div dangerouslySetInnerHTML={{ __html: props.secondParagraph }}></div>
          <Accordion items={props.accordionData}></Accordion>
        </div>
      </div>
    </section>
  )
}

export default VisualWithText
