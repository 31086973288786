import React from "react"
import { setImageParameters } from "../../utils/imageResize"

interface TileProps {
  title: string
  description: string
  image: any
  buttonText: string
  path: string
  isFirst: boolean
  isHighlighted: boolean
}

const Tile: React.FC<TileProps> = props => {
  const imgUrl = setImageParameters(props.image.url, { width: 1000, mode: "min" })
  return (
    <div className={`tile tile__card tile__body tile__info ${props.isFirst ? "tile__info--start" : "tile__info--small"} ${props.isHighlighted ? "tile--highlighted tile__info--start" : ""}`}>
      {props.image !== null && props.image !== undefined && props.image?.url && (
        <img src={imgUrl} alt={props.image.alt} className="tile__img" />
      )}
      <div className="tile__info--wrapper">
        <div className="tile__title" dangerouslySetInnerHTML={{ __html: props.title }}></div>
        <div className="tile__text">
          <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </div>
        <div className="tile__btn">
          {props.path && props.buttonText ? (
            <a href={props.path} className="cta cta--secondary">
              <span>{props.buttonText}</span>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Tile
