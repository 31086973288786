import React, { useState, useEffect } from "react"
import CryptoJS from "crypto-js"
import { requestCrmcontact } from "../../utils/tide-api"
import getTranslation from "../../utils/get-translation"
import translations from "./translations.json"

interface NewsletterProps {
  title?: string
}

declare global {
  interface Window {
    hbspt: {
      forms: {
        create(config: any): void
      }
    }
  }
}

const NewsletterForm: React.FC<NewsletterProps> = ({ title }) => {
  const loadScript = src => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.src = src
      script.async = true

      script.onload = resolve
      script.onerror = reject

      document.head.appendChild(script)
    })
  }

  useEffect(() => {
    loadScript("//js.hsforms.net/forms/embed/v2.js")
      .then(() => {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "9351606",
          formId: "2d2c8ee6-a5d7-4e95-a029-0431d229af6f",
          target: "#hubspotForm",
        })
      })
      .catch(error => {
        console.error("Error loading HubSpot script", error)
      })
  }, [])

  return (
    <div className="newsletter">
      <div className="newsletter__title" dangerouslySetInnerHTML={{ __html: title }}></div>
      <div id="hubspotForm"></div>
    </div>
  )
}

export default NewsletterForm
