import React from "react"
import icons from "../../images/tide-commercial_icons.svg"

interface ShadowTileProps {
  title: string
  icon: string
  description: string
}

const ShadowTile: React.FC<ShadowTileProps> = props => {
  return (
    <div className="tile tile--shadow">
      <div className="tile__body">
        <div className="tile__title tile__title--withicon">
          <svg className="icon icon--tile-window">
            <use href={`${icons}#${props.icon}`}></use>
          </svg>
          <div dangerouslySetInnerHTML={{ __html: props.title }}></div>
        </div>
        <div className="tile__text">
          <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </div>
      </div>
    </div>
  )
}

export default ShadowTile
