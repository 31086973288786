import React from "react"
import { compact } from "lodash"

interface PackagingItemProps {
  index: number
  isOpen: boolean
  handleClick(index: number): void
  navigationTitle: string
}

const PackagingItem: React.FC<PackagingItemProps> = props => {
  const className = compact(["packaging__item", props.isOpen ? "packaging__item--active" : ""]).join(" ")

  return (
    <a className={className} onClick={() => props.handleClick(props.index)}>
      {props.navigationTitle}
    </a>
  )
}

export default PackagingItem
