import React from "react"
import ListItem from "../../shared/list-item"

interface DiagramTileProps {
  title: string
  listItems: any
}

const DiagramTile: React.FC<DiagramTileProps> = props => {
  return (
    <div className="diagram-tiles diagram-tiles--linestopbottom">
      <div className="tile-list">
        <div className="tile-list__tiles">
          <div className="tile tile--centered tile--background">
            <div className="tile__body">
              <div className="tile__title">
                <h3>{props.title}</h3>
              </div>
              <div className="tile__text">
                <div className="tile__list">
                  <ul className="list list--horizontal">
                    {props.listItems.flatMap(l => l.map((i, index) => <ListItem key={index} title={i.content.general.title}></ListItem>))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagramTile
