import Article from "../components/article/article"
import BreadCrumbs from "../components/breadcrumbs/breadcrumbs"
import Clients from "../components/clients/clients"
import ContactForm from "../components/form-contactV2/form-contactV2"
import CtaBlock from "../components/ctablock/ctablock"
import DMSDiagram from "../components/diagram-dms/diagram-dms"
import DemoForm from "../components/form-demo/form-demo"
import Diagram from "../components/diagram/diagram"
import DiagramPim from "../components/diagram-pim/diagram-pim"
import DiagramPlayerhub from "../components/diagram-playerhub/diagram-playerhub"
import DiscoverApplication from "../components/discover-applications/discover-applications"
import DiscoverCore from "../components/discover-core/discover-core"
import DiscoverService from "../components/discover-service/discover-service"
import ExtendedList from "../components/extended-list/extended-list"
import Feature from "../components/feature/feature"
import Footer from "../components/footer/footer"
import FormContact from "../components/form-contact/form-contact"
import { Helmet } from "react-helmet"
import Hero from "../components/hero/hero"
import HeroContent from "../components/hero-content/hero-content"
import HeroPlain from "../components/hero-plain/hero-plain"
import ImageWithText from "../components/image-with-text/image-with-text"
import Integrations from "../components/integrations/integrations"
import Intro from "../components/intro/intro"
import Layout from "../components/layout"
import Nav from "../components/nav/nav"
import NewsletterForm from "../components/newsletter/newsletter"
import Packaging from "../components/packaging/packaging"
import React, { useEffect } from "react"
import SEO from "../components/seo/seo"
import ServicesDiagram from "../components/services-diagram/services-diagram"
import TileImageList from "../components/tile-imagelist/tile-imagelist"
import TileList from "../components/tile-list/tile-list"
import TileSlider from "../components/tile-slider/tile-slider"
import TitleWithText from "../components/title-with-text/title-with-text"
import TitleWithTiles from "../components/title-with-tiles/title-with-tiles"
import VisualWithText from "../components/visual-with-text/visual-with-text"
import { graphql } from "gatsby"
import TileOverview from "../components/tile-overview/tile-overview"
import ArticleText from "../components/article-text/article-text"
import CustomForm from "../components/custom-form/custom-form"

const singlePage = ({ data, location }) => {
  // Clear session storage on page load to remove scroll position
  useEffect(() => {
    sessionStorage.clear()
  }, [])

  const pageData = data.tideItemForPage.childItems

  const footerColumns = data.allTideItemForFooterColumn.edges

  const navigation = data.allTideItemForNavigation.edges[0]
  const navigationItems = data.allTideItemForNavigationItem

  const secondaryNavigationItems = data.allTideItemForSecondaryNavigation.edges[0].node.childItems

  const secondaryFooterItems = data.allTideItemForSecondaryFooter.edges[0].node.childItems
  const footerContact = data.allTideItemForFooter.edges[0].node.content.general

  const getRecursiveChildren = (navigation, navigationItems) => {
    return {
      ...navigation.node,
      children: navigationItems.edges
        .filter(e => e.node.parentNodeId === navigation.node.id)
        .map(x => getRecursiveChildren(x, navigationItems)),
    }
  }

  let navItems = getRecursiveChildren(navigation, navigationItems)
  let footerItemsTop = footerColumns.map(c => getRecursiveChildren(c, navigationItems))

  const sortedPageData = pageData.filter(x => x).sort((a, b) => (a.position > b.position ? 1 : -1))

  return (
    <Layout>
      <Helmet>
        <link rel="icon" href="/icons/favicon/favicon-32x32.png" />
        <link rel="icon" href="/icons/favicon/favicon-16x16.png" />
        <link rel="icon" href="/icons/favicon/favicon.ico" />
        <link rel="icon" href="/icons/favicon/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest/site.webmanifest" />
        <link rel="mask-icon" href="/icons/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c"></meta>
        <meta name="theme-color" content="#ffffff"></meta>
      </Helmet>
      <SEO
        title={data.tideItemForPage.content.general.seoTitle ?? ""}
        description={data.tideItemForPage.content.general.seoDescription ?? ""}
        keywords={data.tideItemForPage.content.general.seoKeywords ?? ""}
        image={data.tideItemForPage.content.general.seoImage?.url ?? ""}
      ></SEO>
      <Nav items={navItems} initallsOpen={false} location={location} secondaryNavigationItems={secondaryNavigationItems}></Nav>
      {sortedPageData.map((p, index) => {
        p.position
        switch (p.templateName) {
          case "ArticleText": {
            return <ArticleText key={index} text={p.content.general?.text}></ArticleText>
          }
          case "TileOverview": {
            return <TileOverview key={index} tileDate={p.childItems}></TileOverview>
          }
          case "Hero":
            return (
              <Hero
                key={index}
                title={p.content.general?.title}
                description={p.content.general?.description}
                buttonText={p.content.general?.buttonText}
                buttonLink={p.content.general?.buttonLink}
                image={p.content.general?.image}
                slogan={p.content.general?.slogan}
                symbol={p.content.general?.symbol}
                question={p.content.general?.question}
              ></Hero>
            )
          case "VisualWithText":
            return (
              <VisualWithText
                key={index}
                title={p.content.general?.title}
                firstParagraph={p.content.general?.firstParagraph}
                secondParagraph={p.content.general?.secondParagraph}
                accordionData={p.childItems}
                image={p.content.general?.picture}
              ></VisualWithText>
            )
          case "ImageList":
            return (
              <TileImageList
                key={index}
                title={p.content.general?.title}
                description={p.content.general?.description}
                childItems={p.childItems}
              ></TileImageList>
            )
          case "TileList":
            return (
              <TileList
                key={index}
                title={p.content.general?.title}
                threeCol={p.content.general?.threeCol}
                fourCol={p.content.general?.fourCol}
                childItems={p.childItems}
              ></TileList>
            )
          case "TileSlider":
            return <TileSlider key={index} title={p.content.general?.title} childItems={p.childItems}></TileSlider>
          case "Ctablock":
            return (
              <CtaBlock
                key={index}
                title={p.content.general?.title}
                description={p.content.general?.description}
                primaryButtonText={p.content.general?.primaryButtonText}
                primaryButtonLink={p.content.general?.primaryButtonLink}
                buttonBetweenText={p.content.general?.buttonBetweenText}
                secondaryButtonText={p.content.general?.secondaryButtonText}
                secondaryButtonLink={p.content.general?.secondaryButtonLink}
              ></CtaBlock>
            )
          case "HeroContent":
            return (
              <HeroContent
                key={index}
                title={p.content.general?.title}
                description={p.content.general?.description}
                image={p.content.general?.image}
              ></HeroContent>
            )
          case "Intro":
            return (
              <Intro
                key={index}
                title={p.content.general?.title}
                firstParagraph={p.content.general?.firstParagraph}
                secondParagraph={p.content.general?.secondParagraph}
                blockTitle={p.content.general?.blockTitle}
                childItems={p.childItems}
              ></Intro>
            )
          case "Diagram":
            return (
              <Diagram
                key={index}
                diagramTiles={p.childItems.filter(x => x.templateName === "DiagramTiles")}
                diagramTile={p.childItems.filter(x => x.templateName === "DiagramTile")}
                diagramTitle={p.childItems.filter(x => x.templateName === "DiagramTitle")}
                diagramTileBottom={p.childItems.filter(x => x.templateName === "DiagramTilesBottomFolder")}
              ></Diagram>
            )
          case "ImageWithText":
            return (
              <ImageWithText
                key={index}
                title={p.content.general?.title}
                image={p.content.general?.image}
                description={p.content.general?.description}
                accordionData={p.childItems}
                hasBackground={p.content.general?.hasBackground}
                reverse={p.content.general?.reverse}
                link={p.content.general?.link}
                linkText={p.content.general?.linkText}
                path={p.content.general?.path}
              ></ImageWithText>
            )
          case "TitleWithTiles":
            return (
              <TitleWithTiles
                key={index}
                align={p.content.general?.align}
                title={p.content.general?.title}
                description={p.content.general?.description}
                reverse={p.content.general?.reverse}
                tileDate={p.childItems}
                image={p.content.general?.image}
              ></TitleWithTiles>
            )
          case "ExtendedList":
            return (
              <ExtendedList
                key={index}
                title={p.content.general?.title}
                description={p.content.general?.description}
                buttonText={p.content.general?.buttonText}
                buttonLink={p.content.general?.buttonLink}
                twoColumn={p.content.general?.twoColumn}
                threeColumn={p.content.general?.threeColumn}
                fourColumn={p.content.general?.fourColumn}
                tileData={p.childItems}
                anchorId={p.content.general?.anchorId}
              ></ExtendedList>
            )
          case "ContactForm":
            return (
              <FormContact
                key={index}
                title={p.content.general?.title}
                description={p.content.general?.description}
                items={p.childItems}
              ></FormContact>
            )
          case "DiscoverCore":
            return (
              <DiscoverCore
                key={index}
                img={p.content.general?.image}
                title={p.content.general?.title}
                quote={p.content.general?.quote}
                tileData={p.childItems}
              ></DiscoverCore>
            )
          case "DiscoverApplication":
            return (
              <DiscoverApplication
                key={index}
                title={p.content.general?.title}
                img={p.content.general?.image}
                quote={p.content.general?.quote}
                tileData={p.childItems}
              ></DiscoverApplication>
            )
          case "DiscoverService":
            return (
              <DiscoverService
                key={index}
                img={p.content.general?.image}
                title={p.content.general?.title}
                quote={p.content.general?.quote}
                tileData={p.childItems}
              ></DiscoverService>
            )
          case "DemoForm":
            return (
              <DemoForm
                key={index}
                image={p.content.general?.image}
                description={p.content.general?.description}
                title={p.content.general?.title}
                items={p.childItems}
              ></DemoForm>
            )
          case "Breadcrumbs":
            return <BreadCrumbs key={index} items={p.childItems}></BreadCrumbs>
          case "PlayerHubDiagram":
            return (
              <DiagramPlayerhub
                key={index}
                title={p.content.general?.titleTop}
                titleBottom={p.content.general?.titleBottom}
                underTitle={p.content.general?.underTitle}
                ShadowTilesData={p.childItems.filter(x => x.templateName === "ShadowTile")}
                BackgroundTilesData={p.childItems.filter(x => x.templateName === "TileItem")}
                rowItem={p.childItems.filter(x => x.templateName === "InventoryRow")}
              ></DiagramPlayerhub>
            )
          case "PimDiagram":
            return (
              <DiagramPim
                key={index}
                title={p.content.general?.title}
                description={p.content.general?.description}
                tileTitle={p.content.general?.tileTitle}
                shadowTileData={p.childItems.filter(x => x.templateName === "ShadowTilePim")}
                backgroundTileData={p.childItems.filter(x => x.templateName === "BackgroundTilePim")}
                backgroundFullTileData={p.childItems.filter(x => x.templateName === "BackgroundTileFullPim")[0]}
              ></DiagramPim>
            )
          case "DmsDiagram":
            return (
              <DMSDiagram
                key={index}
                title={p.content.general?.title}
                description={p.content.general?.description}
                diagramImageItems={p.childItems[0].childItems}
                diagramTileItems={p.childItems[1].childItems}
              />
            )
          case "ServicesDiagram":
            return (
              <ServicesDiagram
                key={index}
                innerTitle={p.content.general?.innerTitle}
                innerDescription={p.content.general?.innerText}
                diagramTitle={p.content.general?.diagramTitle}
                diagramDescription={p.content.general?.diagramText}
                servicesDiagramItems={p.childItems}
                buttonLink={p.content.general?.buttonLink}
                buttonTitle={p.content.general?.buttonTitle}
              ></ServicesDiagram>
            )
          case "TitleWithText":
            return (
              <TitleWithText
                key={index}
                title={p.content.general?.title}
                description={p.content.general?.description}
                buttonLink={p.content.general?.buttonLink}
                buttonText={p.content.general?.buttonText}
              ></TitleWithText>
            )
          case "Feature":
            return (
              <Feature
                key={index}
                label={p.content.general?.label}
                title={p.content.general?.title}
                description={p.content.general?.description}
                buttonTitle={p.content.general?.buttonText}
                buttonLink={p.content.general?.buttonLink}
                buttonUrl={p.content.general?.buttonUrl}
                anchorId={p.content.general?.anchorId}
                img={p.content.general?.image}
                reverse={p.content.general?.reverse}
              ></Feature>
            )
          case "Integrations":
            return (
              <Integrations
                key={index}
                label={p.content.general?.label}
                title={p.content.general?.title}
                description={p.content.general?.description}
                buttonTitle={p.content.general?.buttonText}
                buttonLink={p.content.general?.buttonLink}
                integrationItems={p.childItems}
              ></Integrations>
            )
          case "Packaging":
            return <Packaging key={index} title={p.content.general?.title} packages={p.childItems}></Packaging>
          case "Clients":
            return <Clients key={index} items={p.childItems}></Clients>
          case "HeroPlain":
            return <HeroPlain key={index} title={p.content.general?.title} description={p.content.general?.description}></HeroPlain>
          case "Article":
            return (
              <Article
                key={index}
                text={p.content.general?.text}
                sidebarTitle={p.content.sidebar?.title}
                sidebarDescription={p.content.sidebar?.description}
                articleItems={p.childItems}
              ></Article>
            )
          case "ContactFormV2":
            return (
              <ContactForm
                key={index}
                buttonText={p.content.general?.buttonText}
                disclaimerPath={p.content.general?.privacyStatementLink}
                demoTag={p.content.tags?.demoTag?.tideId}
                description={p.content.general?.intro}
                successDescription={p.content.general?.submittedIntro}
                title={p.content.general?.title}
                successTitle={p.content.general?.submittedTitle}
                emailSubject={p.content.internalMail?.subject}
                emailMessage={p.content.internalMail?.message}
                confirmationEmailTemplate={p.content.externalMail?.templateConfirmationMail?.tideId}
                sidebarBlueTitle={p.content.sideBarBlue?.title}
                sidebarBlueDescription={p.content.sideBarBlue?.description}
                sidebarShadowTitle={p.content.sideBarShadow?.title}
                sidebarShadowDescription={p.content.sideBarShadow?.description}
              ></ContactForm>
            )
          case "NewsletterFormV2":
            return <NewsletterForm key={index} title={p.content.general?.title}></NewsletterForm>
          case "CustomForm":
            return <CustomForm
              key={index}
              title={p.content.general?.title}
              portalId={p.content.general?.portalId}
              formId={p.content.general?.formId}
              meetingSource={p.content.general?.meetingSource}
              sidebarBlueTitle={p.content.sideBarBlue?.title}
              sidebarBlueDescription={p.content.sideBarBlue?.description}
              sidebarShadowTitle={p.content.sideBarShadow?.title}
              sidebarShadowDescription={p.content.sideBarShadow?.description}
            ></CustomForm>
        }
      })}
      <Footer
        key={footerItemsTop.id}
        itemsTop={footerItemsTop}
        secondaryFooterItems={secondaryFooterItems}
        contact={footerContact}
      ></Footer>
    </Layout>
  )
}

export default singlePage

export const pageQuery = graphql`
  query page($id: String!, $language: String!) {
    tideItemForPage(id: { eq: $id }, language: { eq: $language }) {
      id
      language
      content {
        general {
          path
          seoDescription
          seoKeywords
          seoTitle
          seoImage {
            url
            title
            altText
          }
        }
      }
      childItems {
        ... on TideItemForArticleText {
          id
          name
          position
          templateName
          language
          content {
            general {
              text
            }
          }
        }
        ... on TideItemForTileOverview {
          id
          name
          position
          templateName
          language
          childItems {
            ... on TideItemForTileItem {
              id
              name
              position
              templateName
              language
              content {
                general {
                  buttonText
                  description
                  hasIcon
                  icon
                  image {
                    altText
                    title
                    url
                  }
                  isHighlighted
                  path
                  title
                }
              }
            }
          }
        }
        ... on TideItemForNewsletterFormV2 {
          position
          templateName
          language
          name
          id
          content {
            general {
              buttonText
              privacyStatement
              privacyStatementLink
              submittedTitle
              title
            }
            externalMail {
              templateConfirmationMailId
              templateConfirmationMail {
                tideId
              }
            }
            tags {
              newsletterTagId
              newsletterTag {
                tideId
              }
            }
          }
        }
        ... on TideItemForContactFormV2 {
          position
          templateName
          language
          id
          name
          content {
            general {
              buttonText
              intro
              privacyStatement
              privacyStatementLink
              submittedIntro
              submittedTitle
              title
            }
            externalMail {
              templateConfirmationMailId
              templateConfirmationMail {
                tideId
              }
            }
            internalMail {
              subject
              message
            }
            sideBarBlue {
              title
              description
            }
            sideBarShadow {
              title
              description
            }
            tags {
              demoTag {
                tideId
              }
            }
          }
        }
        ... on TideItemForCustomForm {
          position
          templateName
          language
          name
          id
          content {
            general {
              title
              portalId
              formId
              meetingSource
            }
            sideBarBlue {
              title
              description
            }
            sideBarShadow {
              title
              description
            }
          }
        }
        ... on TideItemForArticle {
          position
          templateName
          language
          name
          id
          content {
            general {
              text
            }
            sidebar {
              description
              title
            }
          }
          childItems {
            ... on TideItemForArticleItem {
              id
              name
              templateName
              language
              content {
                general {
                  title
                  path
                }
              }
            }
          }
        }
        ... on TideItemForHeroPlain {
          position
          id
          language
          name
          templateName
          content {
            general {
              description
              title
            }
          }
        }
        ... on TideItemForNewsletterForm {
          position
          templateName
          name
          id
          language
          content {
            general {
              description
              title
            }
          }
          childItems {
            ... on TideItemForFormLabel {
              id
              name
              templateName
              language
              content {
                general {
                  inputName
                  labelText
                  required
                }
              }
            }
          }
        }
        ... on TideItemForClients {
          position
          id
          language
          name
          templateName
          childItems {
            ... on TideItemForClientItem {
              id
              name
              templateName
              language
              content {
                general {
                  image {
                    altText
                    url
                  }
                }
              }
            }
          }
        }
        ... on TideItemForDmsDiagram {
          position
          id
          name
          templateName
          language
          content {
            general {
              description
              title
            }
          }
          childItems {
            ... on TideItemForDmsDiagramImages {
              id
              name
              templateName
              language
              childItems {
                ... on TideItemForDmsDiagramImageTile {
                  id
                  name
                  templateName
                  language
                  content {
                    general {
                      image {
                        altText
                        url
                      }
                    }
                  }
                }
              }
            }
            ... on TideItemForDmsDiagramTiles {
              id
              name
              templateName
              language
              childItems {
                ... on TideItemForTileItem {
                  id
                  name
                  content {
                    general {
                      title
                      description
                      icon
                    }
                  }
                }
              }
            }
          }
        }
        ... on TideItemForPackaging {
          position
          name
          language
          templateName
          id
          content {
            general {
              title
            }
          }
          childItems {
            ... on TideItemForPackage {
              id
              name
              templateName
              language
              content {
                general {
                  ctaText
                  ctaUrl
                  description
                  image {
                    altText
                    url
                  }
                  navigationTitle
                  title
                }
              }
            }
          }
        }
        ... on TideItemForIntegrations {
          position
          name
          language
          templateName
          id
          content {
            general {
              title
              label
              description
              buttonText
              buttonLink
            }
          }
          childItems {
            ... on TideItemForIntegrationsItem {
              id
              name
              templateName
              language
              content {
                general {
                  image {
                    altText
                    url
                  }
                }
              }
            }
          }
        }
        ... on TideItemForFeature {
          position
          language
          name
          templateName
          id
          content {
            general {
              anchorId
              buttonLink
              buttonUrl
              buttonText
              description
              label
              title
              image {
                altText
                url
              }
              reverse
            }
          }
        }
        ... on TideItemForTitleWithText {
          position
          language
          id
          templateName
          name
          content {
            general {
              description
              title
              buttonLink
              buttonText
            }
          }
        }
        ... on TideItemForServicesDiagram {
          position
          id
          name
          templateName
          language
          content {
            general {
              buttonTitle
              buttonLink
              diagramText
              diagramTitle
              innerText
              innerTitle
            }
          }
          childItems {
            ... on TideItemForServiceDiagramItem {
              id
              name
              templateName
              language
              content {
                general {
                  icon
                  title
                  textRight
                  textBottom
                  textLeft
                }
              }
            }
          }
        }
        ... on TideItemForPimDiagram {
          position
          id
          name
          templateName
          language
          content {
            general {
              title
              tileTitle
              description
            }
          }
          childItems {
            ... on TideItemForShadowTilePim {
              id
              name
              templateName
              language
              content {
                general {
                  description
                  icon
                  title
                }
              }
            }
            ... on TideItemForBackgroundTilePim {
              id
              name
              templateName
              language
              content {
                general {
                  buttonText
                  description
                  link
                  title
                }
              }
            }
            ... on TideItemForBackgroundTileFullPim {
              id
              name
              templateName
              content {
                general {
                  description
                  title
                }
              }
              language
            }
          }
        }
        ... on TideItemForPlayerHubDiagram {
          position
          templateName
          name
          language
          id
          content {
            general {
              titleTop
              titleBottom
              underTitle
            }
          }
          childItems {
            ... on TideItemForInventoryRow {
              id
              name
              templateName
              language
              content {
                general {
                  description
                  image {
                    altText
                    url
                  }
                  secondDescription
                  title
                  icons
                  firstIcon
                  secondIcon
                  thirdIcon
                  fourthIcon
                  fifthIcon
                }
              }
            }
            ... on TideItemForShadowTile {
              id
              name
              templateName
              language
              content {
                general {
                  title
                  icon
                }
              }
            }
            ... on TideItemForTileItem {
              id
              name
              templateName
              language
              content {
                general {
                  title
                  description
                }
              }
            }
          }
        }
        ... on TideItemForBreadcrumbs {
          position
          id
          name
          templateName
          language
          childItems {
            ... on TideItemForListItem {
              id
              name
              templateName
              language
              content {
                general {
                  path
                  title
                }
              }
            }
          }
        }
        ... on TideItemForDemoForm {
          position
          id
          name
          templateName
          language
          content {
            general {
              description
              image {
                altText
                url
              }
              title
            }
          }
          childItems {
            ... on TideItemForFormLabel {
              id
              name
              templateName
              language
              content {
                general {
                  inputName
                  labelText
                  required
                }
              }
            }
          }
        }
        ... on TideItemForDiscoverService {
          position
          id
          name
          templateName
          language
          content {
            general {
              image {
                altText
                url
              }
              quote
              title
            }
          }
          childItems {
            ... on TideItemForTileItem {
              id
              name
              templateName
              language
              content {
                general {
                  buttonText
                  description
                  path
                  title
                }
              }
            }
          }
        }
        ... on TideItemForDiscoverApplication {
          position
          id
          name
          templateName
          language
          content {
            general {
              image {
                altText
                url
              }
              quote
              title
            }
          }
          childItems {
            ... on TideItemForTileItem {
              id
              name
              templateName
              language
              content {
                general {
                  icon
                  description
                  title
                }
              }
            }
          }
        }
        ... on TideItemForDiscoverCore {
          position
          id
          name
          templateName
          language
          content {
            general {
              image {
                altText
                url
              }
              quote
              title
            }
          }
          childItems {
            ... on TideItemForTileItem {
              id
              name
              templateName
              language
              content {
                general {
                  title
                  path
                  image {
                    altText
                    url
                  }
                  description
                  buttonText
                }
              }
            }
          }
        }
        ... on TideItemForContactForm {
          position
          id
          name
          templateName
          language
          content {
            general {
              title
              description
            }
          }
          childItems {
            ... on TideItemForFormLabel {
              id
              name
              templateName
              language
              content {
                general {
                  inputName
                  labelText
                  required
                }
              }
            }
          }
        }
        ... on TideItemForTitleWithTiles {
          position
          id
          name
          templateName
          language
          content {
            general {
              align
              description
              title
              reverse
              image {
                altText
                url
              }
            }
          }
          childItems {
            ... on TideItemForTileItem {
              id
              name
              templateName
              language
              content {
                general {
                  title
                  description
                }
              }
            }
            ... on TideItemForTileLogoItem {
              id
              name
              templateName
              language
              content {
                general {
                  image {
                    altText
                    url
                  }
                }
              }
            }
          }
        }
        ... on TideItemForHero {
          position
          templateName
          language
          content {
            general {
              title
              description
              buttonText
              buttonLink
              image {
                altText
                url
              }
              slogan
              symbol {
                url
              }
              question
            }
          }
        }
        ... on TideItemForImageList {
          position
          templateName
          language
          content {
            general {
              description
              title
            }
          }
          childItems {
            ... on TideItemForImageListTile {
              templateName
              language
              content {
                general {
                  description
                  image {
                    altText
                    url
                  }
                  link
                  path
                  title
                }
              }
            }
          }
        }
        ... on TideItemForImageWithText {
          position
          templateName
          language
          content {
            general {
              image {
                altText
                url
              }
              title
              description
              reverse
              hasBackground
              link
              linkText
              path
            }
          }
          childItems {
            ... on TideItemForAccordion {
              id
              name
              templateName
              language
              childItems {
                ... on TideItemForAccordionItem {
                  id
                  name
                  templateName
                  language
                  content {
                    general {
                      description
                      title
                      link
                      buttonText
                    }
                  }
                }
              }
            }
          }
        }
        ... on TideItemForVisualWithText {
          position
          templateName
          language
          content {
            general {
              picture {
                url
              }
              firstParagraph
              secondParagraph
              title
            }
          }
          childItems {
            ... on TideItemForAccordion {
              templateName
              language
              id
              childItems {
                ... on TideItemForAccordionItem {
                  language
                  id
                  content {
                    general {
                      description
                      title
                      link
                      buttonText
                    }
                  }
                }
              }
            }
          }
        }
        ... on TideItemForTileList {
          position
          id
          templateName
          language
          content {
            general {
              threeCol
              fourCol
              title
            }
          }
          childItems {
            ... on TideItemForTileItem {
              id
              templateName
              language
              content {
                general {
                  buttonText
                  description
                  image {
                    altText
                    url
                  }
                  path
                  title
                  icon
                }
              }
            }
          }
        }
        ... on TideItemForTileSlider {
          position
          id
          name
          language
          templateName
          content {
            general {
              title
            }
          }
          childItems {
            ... on TideItemForTileItem {
              id
              name
              templateName
              language
              content {
                general {
                  buttonText
                  description
                  image {
                    altText
                    url
                  }
                  path
                  title
                }
              }
            }
          }
        }
        ... on TideItemForCtablock {
          position
          id
          name
          language
          templateName
          content {
            general {
              title
              description
              primaryButtonText
              primaryButtonLink
              buttonBetweenText
              secondaryButtonText
              secondaryButtonLink
            }
          }
        }
        ... on TideItemForHeroContent {
          position
          id
          name
          language
          templateName
          content {
            general {
              description
              image {
                altText
                url
              }
              title
            }
          }
        }
        ... on TideItemForIntro {
          position
          id
          name
          language
          templateName
          content {
            general {
              blockTitle
              firstParagraph
              secondParagraph
              title
            }
          }
          childItems {
            ... on TideItemForListItem {
              id
              name
              templateName
              language
              content {
                general {
                  title
                }
              }
            }
          }
        }
        ... on TideItemForDiagram {
          position
          id
          name
          language
          templateName
          childItems {
            ... on TideItemForDiagramTiles {
              id
              name
              templateName
              language
              content {
                general {
                  blockLowerTitle1
                  blockLowerTitle2
                  blockLowerTitle3
                  blockTitle1
                  blockTitle2
                }
              }
              childItems {
                ... on TideItemForListItem {
                  id
                  name
                  language
                  templateName
                  content {
                    general {
                      title
                    }
                  }
                }
                ... on TideItemForImageListItem {
                  id
                  name
                  templateName
                  language
                  content {
                    general {
                      firstTitle
                      secondTitle
                      image {
                        altText
                        url
                      }
                      icons
                      firstIcon
                      secondIcon
                      thirdIcon
                      fourthIcon
                      fifthIcon
                    }
                  }
                }
              }
            }
            ... on TideItemForDiagramTilesBottomFolder {
              id
              name
              templateName
              language
              childItems {
                ... on TideItemForDiagramTilesBottom {
                  id
                  name
                  templateName
                  language
                  content {
                    general {
                      title
                      icon
                    }
                  }
                  childItems {
                    ... on TideItemForListItem {
                      id
                      name
                      templateName
                      language
                      content {
                        general {
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on TideItemForDiagramTile {
              id
              name
              templateName
              language
              content {
                general {
                  title
                }
              }
              childItems {
                ... on TideItemForListItem {
                  id
                  name
                  templateName
                  language
                  content {
                    general {
                      title
                    }
                  }
                }
              }
            }
            ... on TideItemForDiagramTitle {
              id
              name
              templateName
              language
              content {
                general {
                  title
                }
              }
            }
          }
        }
        ... on TideItemForExtendedList {
          position
          id
          name
          templateName
          language
          content {
            general {
              title
              description
              buttonText
              buttonLink
              twoColumn
              threeColumn
              fourColumn
              anchorId
            }
          }
          childItems {
            ... on TideItemForTileItem {
              id
              name
              templateName
              language
              content {
                general {
                  title
                  description
                  isHighlighted
                  hasIcon
                  path
                  buttonText
                }
              }
            }
          }
        }
      }
    }
    allTideItemForSecondaryNavigation(sort: { fields: childItems___parentItem___position }) {
      edges {
        node {
          childItems {
            ... on TideItemForNavigationItem {
              id
              name
              language
              templateName
              content {
                general {
                  path
                  title
                  description
                }
              }
              childItems {
                ... on TideItemForNavigationItem {
                  id
                  name
                  language
                  templateName
                  content {
                    general {
                      path
                      url
                      title
                      description
                      lock
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allTideItemForNavigation {
      edges {
        node {
          id
        }
      }
    }
    allTideItemForNavigationItem(sort: { order: ASC, fields: position }) {
      edges {
        node {
          id
          parentNodeId
          content {
            general {
              description
              isCtaBlock
              title
              path
            }
            cta {
              ctaText
            }
          }
        }
      }
    }
    allTideItemForFooterColumn {
      edges {
        node {
          id
        }
      }
    }
    tideItemForFooterContact {
      content {
        gatsby {
          gatsby
        }
      }
      parentNodeId
    }
    allTideItemForFooter {
      edges {
        node {
          templateName
          name
          language
          id
          content {
            general {
              phone
              email
            }
          }
        }
      }
    }
    allTideItemForSecondaryFooter {
      edges {
        node {
          childItems {
            ... on TideItemForNavigationItem {
              id
              name
              templateName
              language
              content {
                general {
                  path
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
