import React from "react"
import { buildClassName } from "../../utils/classNameBuilder"
import icons from "../../images/tide-commercial_icons.svg"

interface ServicesDiagramItemProps {
  title: string
  icon: string
  right?: boolean
  left?: boolean
  bottom?: boolean
}

const ServicesDiagramItem: React.FC<ServicesDiagramItemProps> = props => {
  const className = buildClassName([
    buildClassName(["services-diagram__service", props.right && "services-diagram__service--text-right"]),
    buildClassName(["services-diagram__service", props.left && "services-diagram__service--text-left"]),
    props.bottom && "services-diagram__service--text-bottom",
  ])
  return (
    <li>
      <span className={className}>
        <svg className="icon">
          <use href={`${icons}#${props.icon}`}></use>
        </svg>
        <h4>{props.title}</h4>
      </span>
    </li>
  )
}

export default ServicesDiagramItem
