import React from "react"
import icons from "../../images/tide-commercial_icons.svg"

interface BackgroundTileProps {
  title: string
  description: string
  image: any
  secondDescription: string
  icons: boolean
  firstIcon: string
  secondIcon: string
  thirdIcon: string
  fourthIcon: string
  fifthIcon: string
}

const BackgroundTile: React.FC<BackgroundTileProps> = props => {
  return props.icons ? (
    <div className="tile__inventory-row tile__inventory-row--icons">
      <div className="tile__inventory-item" dangerouslySetInnerHTML={{ __html: props.title }}></div>
      <div className="tile__inventory-item">
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.firstIcon}`}></use>
        </svg>
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.secondIcon}`}></use>
        </svg>
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.thirdIcon}`}></use>
        </svg>
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.fourthIcon}`}></use>
        </svg>
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.fifthIcon}`}></use>
        </svg>
      </div>
    </div>
  ) : (
    <div className="tile__inventory-row">
      <div className="tile__inventory-item" dangerouslySetInnerHTML={{ __html: props.title }}></div>
      <div className="tile__inventory-item" dangerouslySetInnerHTML={{ __html: props.description }}></div>
      <div className="tile__inventory-item">
        <img src={props.image} alt="" />
      </div>
      <div className="tile__inventory-item" dangerouslySetInnerHTML={{ __html: props.secondDescription }}></div>
    </div>
  )
}

export default BackgroundTile
