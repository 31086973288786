import React, { useState } from "react"
import PackagingContent from "./packaging-content"
import PackagingItem from "./packaging-item"

interface PackagingProps {
  title: string
  packages: any
}

const Packaging: React.FC<PackagingProps> = props => {
  const packageItems = props.packages

  const [isOpen, setIsOpen] = useState(
    Array(packageItems.length)
      .fill(false)
      .map((_, i) => i === 0)
  )

  function handleClick(index: number) {
    const newIsOpen = Array(packageItems.length).fill(false)
    newIsOpen[index] = true
    setIsOpen(newIsOpen)
  }

  return (
    <div className="packaging">
      <div className="packaging__items">
        {packageItems.map((c, index) => (
          <PackagingItem
            key={index}
            navigationTitle={c.content.general.navigationTitle}
            index={index}
            isOpen={isOpen[index]}
            handleClick={handleClick}
          ></PackagingItem>
        ))}
      </div>
      <div className="packaging__content">
        {packageItems.map((c, index) => (
          <PackagingContent
            key={index}
            isOpen={isOpen[index]}
            title={c.content.general.title}
            description={c.content.general.description}
            buttonText={c.content.general.ctaText}
            buttonLink={c.content.general.ctaUrl}
            image={c.content.general.image}
          ></PackagingContent>
        ))}
      </div>
    </div>
  )
}

export default Packaging
