import React from "react"
import icons from "../../images/tide-commercial_icons.svg"

interface TileListItemProps {
  icon?: string
  tileTitle: string
  tileDescription: string
  buttonText: string
  path: string
}

const TileListItem: React.FC<TileListItemProps> = props => {
  return (
    <a href={"/" + props.path} className="tile tile__link">
      <div className="tile__body">
        {props.icon ? (
          <div className="tile__title tile__title--withicon">
            <svg className="icon icon--tile-email">
              <use href={`${icons}#${props.icon}`}></use>
            </svg>
            <div className="tile__title">
              <div dangerouslySetInnerHTML={{ __html: props.tileTitle }}></div>
            </div>
          </div>
        ) : (
          <div className="tile__title">
            <div dangerouslySetInnerHTML={{ __html: props.tileTitle }}></div>
          </div>
        )}
        <div className="tile__text">
          <div dangerouslySetInnerHTML={{ __html: props.tileDescription }}></div>
        </div>
        <div className="link link--witharrow">
          <span>{props.buttonText}</span>
        </div>
      </div>
    </a>
  )
}

export default TileListItem
