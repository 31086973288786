import React from "react"

interface ListItemProps {
  path?: string
  text?: string
  image?: any
  title?: string
}

const ListItem: React.FC<ListItemProps> = props => {
  return (
    <>
      {props.path && props.text && (
        <li>
          <a href={props.path}>{props.text}</a>
        </li>
      )}
      {props.image && (
        <li>
          <img src={props.image.url}></img>
        </li>
      )}
      {props.title && <li>{props.title}</li>}
      {props.text && !props.path && <li>{props.text}</li>}
    </>
  )
}

export default ListItem
