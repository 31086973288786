import React from "react"
import { buildClassName } from "../../utils/classNameBuilder"
import icons from "../../images/tide-commercial_icons.svg"

interface TileProps {
  title: string
  description: string
  isHighlighted: boolean
  hasIcon: boolean
  ctaLink?: string
  ctaTekst?: string
}

const Tile: React.FC<TileProps> = props => {
  const className = buildClassName(["tile tile--transparent", props.isHighlighted && "tile--highlighted"])
  const classNameTitle = buildClassName(["tile__title", props.hasIcon && "tile__title--withicon"])

  return (
    <div className={className}>
      <div className="tile__body">
        <div className={classNameTitle}>
          {props.hasIcon && (
            <svg className="icon icon--tile-window">
              <use href={`${icons}#logo--small`}></use>
            </svg>
          )}
          <div dangerouslySetInnerHTML={{ __html: props.title }}></div>
        </div>
        <div className="tile__text" dangerouslySetInnerHTML={{ __html: props.description }}></div>
        {props.ctaLink && (
          <a href={props.ctaLink} className="link link--witharrow">
            <span>{props.ctaTekst}</span>
          </a>
        )}
      </div>
    </div>
  )
}

export default Tile
