import React from "react"

interface ArticleProps {
  text: string
  sidebarTitle?: string
  sidebarDescription?: string
  articleItems?: any
}

const Article: React.FC<ArticleProps> = props => {
  return (
    <div className="article article--withsidebar">
      <div className="article__body" dangerouslySetInnerHTML={{ __html: props.text }}></div>
      <div className="article__sidebar">
        <div className="tile tile--shadow">
          <div className="tile__body">
            <div className="tile__title">
              <h3>{props.sidebarTitle}</h3>
            </div>
            <div className="tile__text">
              <p>{props.sidebarDescription}</p>
              <ul>
                {props.articleItems.map((a, index) => (
                  <li key={index}>
                    <a href={a.content.general.path}>{a.content.general.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Article
