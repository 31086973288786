import React from "react"
import Tile from "./tile"
import { buildClassName } from "../../utils/classNameBuilder"
import useScrollToAnchor from "../../shared/anchor-nav"

interface ExtendedListProps {
  title: string
  description: string
  buttonText: string
  buttonLink: string
  twoColumn: boolean
  fourColumn: boolean
  threeColumn: boolean
  tileData: any
  anchorId: string
}

const ExtendedList: React.FC<ExtendedListProps> = props => {
  useScrollToAnchor()
  const className = buildClassName([
    "extended-list",
    props.twoColumn && "extended-list--2col",
    props.fourColumn && "extended-list--4col",
    props.threeColumn && "extended-list--3col",
    "extended-list--bgtertiary",
  ])
  return (
    <section>
      <div className={className} id={props.anchorId}>
        <div className="extended-list__container">
          <div
            className="extended-list__title"
            dangerouslySetInnerHTML={{
              __html: props.title
                .toString()
                .replace(/<strong>/g, '<span class="bold">')
                .replace(/<\/strong>/g, "</span>"),
            }}
          ></div>
          <div className="extended-list__description" dangerouslySetInnerHTML={{ __html: props.description }}></div>
          <div className="extended-list__tiles">
            {props.tileData.map((t, index) => (
              <Tile
                key={index}
                title={t.content.general.title}
                description={t.content.general.description}
                isHighlighted={t.content.general.isHighlighted}
                hasIcon={t.content.general.hasIcon}
                ctaLink={t.content.general.path}
                ctaTekst={t.content.general.buttonText}
              ></Tile>
            ))}
          </div>
          {props.buttonLink ? (
            <div className="extended-list__cta">
              <a href={"/" + props.buttonLink} className="cta cta--tertiary">
                <span>{props.buttonText}</span>
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  )
}

export default ExtendedList
