import React from "react"
import ListItem from "../../shared/list-item"
import icons from "../../images/tide-commercial_icons.svg"

interface TilesbottomItemProps {
  icon: string
  title: string
  listItem: any
}

const TilesbottomItem: React.FC<TilesbottomItemProps> = props => {
  return (
    <div className="tile tile--shadow">
      <div className="tile__body">
        <div className="tile__title tile__title--withicon">
          <svg className="icon icon--tile-window">
            <use href={`${icons}#${props.icon}`}></use>
          </svg>
          <h3>{props.title}</h3>
        </div>
        <div className="tile__text">
          <ul className="list">
            {props.listItem.map((l, index) => (
              <ListItem key={index} title={l.content.general.title}></ListItem>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TilesbottomItem
