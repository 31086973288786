import React from "react"

interface TileProps {
  image: any
  title: string
  description: string
  link: string
  path: string
}

const Tile: React.FC<TileProps> = props => {
  return (
    <a href={"/" + props.path} className="tile tile__link tile--transparent">
      <div className="tile__body">
        <div className="tile__media ">
          <img src={props.image} className="tile__mediacontent" alt="" />
        </div>
        <div className="tile__title">
          <h3>{props.title}</h3>
        </div>
        <div className="tile__text">
          <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </div>
        <div className="link link--witharrow">
          <span>{props.link}</span>
        </div>
      </div>
    </a>
  )
}

export default Tile
