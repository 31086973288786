import React from "react"
import TilesbottomItem from "./diagram-tilesbottom-item"

interface DiagramTilesbottomProps {
  items: any
}

const DiagramTilesbottom: React.FC<DiagramTilesbottomProps> = props => {
  return (
    <div className="diagram-tiles diagram-tiles--rectangletop">
      <div className="tile-list tile-list--3col">
        <div className="tile-list__tiles">
          {props.items.flatMap(i =>
            i.childItems.map((c, index) => (
              <TilesbottomItem
                key={index}
                icon={c.content.general.icon}
                title={c.content.general.title}
                listItem={c.childItems}
              ></TilesbottomItem>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default DiagramTilesbottom
