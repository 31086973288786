import React, { useEffect } from "react";

interface CustomFormProps {
  title?: string;
  portalId?: string;
  formId?: string;
  meetingSource?: string;
  sidebarBlueTitle: string;
  sidebarBlueDescription: string;
  sidebarShadowTitle: string;
  sidebarShadowDescription: string;
}

const CustomForm: React.FC<CustomFormProps> = props => {

  console.log('CUSTOM FORM');

  const loadScript = src => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.src = src
      script.async = true

      script.onload = resolve
      script.onerror = reject

      document.head.appendChild(script)
    })
  }

  useEffect(() => {
    if (props.portalId && props.formId) {
      loadScript("//js.hsforms.net/forms/embed/v2.js")
        .then(() => {
          window.hbspt.forms.create({
            region: "na1",
            portalId: props.portalId,
            formId: props.formId,
            target: "#hubspotForm",
          })
        })
        .catch(error => {
          console.error("Error loading HubSpot script", error)
        });
    } else if (props.meetingSource) {
      loadScript("//static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js")
        .catch(error => {
          console.error("Error loading HubSpot script", error)
        });
    }
  }, []);

  return (
    <>
      <div className="form-custom">
        <div className="form-custom__text">
          {props.title && (<div className="form-custom__title" dangerouslySetInnerHTML={{ __html: props.title }}></div>)}
          {props.portalId && props.formId && (<div id="hubspotForm"></div>)}
          {props.meetingSource && (<div className="meetings-iframe-container" data-src={props.meetingSource}></div>)}
        </div>

        <div className="form-custom__sidebar">
          <div className="tile tile--darkbackground">
            <div className="tile__body">
              <div className="tile__title">
                <h3>{props.sidebarBlueTitle}</h3>
              </div>
              <div className="tile__text" dangerouslySetInnerHTML={{ __html: props.sidebarBlueDescription }}></div>
            </div>
          </div>
          <div className="tile tile--shadow">
            <div className="tile__body">
              <div className="tile__title">
                <h3>{props.sidebarShadowTitle}</h3>
              </div>
              <div className="tile__text" dangerouslySetInnerHTML={{ __html: props.sidebarShadowDescription }}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomForm;