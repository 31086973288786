import ProcessPlaceholders, { ProcessPlaceholdersConfig } from "../../utils/process-placeholders"
import React, { useEffect, useState } from "react"
import { requestContact, requestCrmcontact } from "../../utils/tide-api"
import { sortedUniq, without } from "lodash"
import getTranslation from "../../utils/get-translation"
import translations from "./translations.json"
declare let gtag: Function;

interface ContactFormProps {
  title?: string
  successTitle?: string
  description?: string
  demoTag?: number
  successDescription?: string
  disclaimerPath?: string
  buttonText?: string
  emailSubject?: string
  emailMessage?: string
  confirmationEmailTemplate?: number
  sidebarBlueTitle: string
  sidebarBlueDescription: string
  sidebarShadowTitle: string
  sidebarShadowDescription: string
}

interface ContactFormValues {
  firstName: string
  lastName: string
  email: string
  tel: string
  remarks: string
  privacy: boolean
  demo: boolean
}

const ContactForm: React.FC<ContactFormProps> = ({
  title,
  successTitle,
  description,
  demoTag,
  successDescription,
  disclaimerPath,
  buttonText,
  emailSubject,
  emailMessage,
  confirmationEmailTemplate,
  sidebarBlueTitle,
  sidebarBlueDescription,
  sidebarShadowTitle,
  sidebarShadowDescription,
}) => {
  const initialValues: ContactFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    remarks: "",
    privacy: false,
    demo: false,
  }

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({} as any)
  const [touched, setTouched] = useState({} as any)
  const [submitTouched, setSubmitTouched] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [tags, setTags] = useState<number[]>([])
  const [language] = useState("en")

  const isValid = Object.keys(errors).length === 0

  useEffect(() => {
    validate()
  }, [values])

  const validate = () => {
    const errors = {} as any

    if (values.firstName.length === 0) {
      errors.firstName = "Firstname required"
    }

    if (values.lastName.length === 0) {
      errors.lastName = "Lastname required"
    }

    if (values.tel.length === 0) {
      errors.tel = "Phone required"
    }

    if (values.remarks.length === 0) {
      errors.remarks = "Message required"
    }

    if (!values.privacy) {
      errors.privacy = "Privacy required"
    }

    if (values.email.length === 0) {
      errors.email = "Email required"
    } else if (!/. *@. */.test(values.email)) {
      errors.email = "Email is not valid"
    }

    setErrors(errors)
  }

  const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = e => {
    setTouched(
      Object.assign({}, touched, {
        [e.target.name]: true,
      })
    )
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> = e => {
    switch (e.target.type) {
      case "radio":
      case "checkbox":
        setValues(
          Object.assign({}, values, {
            [e.target.name]: (e.target as HTMLInputElement).checked,
          })
        )
        break
      case "select":
        setValues(
          Object.assign({}, values, {
            [e.target.name]: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex],
          })
        )
        break
      default:
        setValues(
          Object.assign({}, values, {
            [e.target.name]: e.target.value,
          })
        )
        break
    }
    validate()
  }

  const handleTagsChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const id = parseInt(e.target.value)
    setTags(e.target.checked ? sortedUniq([...tags, id]) : without(tags, id))
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()

    setTouched(
      Object.keys(values).reduce<Record<string, boolean>>((prev, curr) => {
        prev[curr] = true
        return prev
      }, {})
    )
    setSubmitTouched(true)

    if (isValid) {
      try {
        // Create CRM contact
        await requestCrmcontact({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          tags: tags,
          confirmationEmailTemplate: confirmationEmailTemplate,
          LanguageCode: language,
        })

        // Create contact mail
        const config: ProcessPlaceholdersConfig = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.tel,
          message: values.remarks,
          demo: values.demo.toString(),
        }

        const subject = ProcessPlaceholders(emailSubject ?? "", config)
        const message = ProcessPlaceholders(emailMessage ?? "", config)

        await requestContact({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.tel,
          subject: subject,
          message: message,
        })
        setSubmitted(true)
        window.scrollTo(0, 0)

        gtag('event', 'conversion', {
          'send_to': 'AW-330869356/tsD2CMjgotsCEOzU4p0B'
        });
      } catch (e) {
        console.error(e)
      }
    }
  }

  return (
    <section className="form-contact">
      <>
        <div className="form-contact__text">
          <h2>{submitted ? successTitle : title}</h2>
          <div dangerouslySetInnerHTML={{ __html: (submitted ? successDescription : description) ?? "" }}></div>
          {!submitted && (
            <div className="form form--contact">
              <form className="form form--contact" onSubmit={handleSubmit}>
                <div className="form__row form__row--2col">
                  <div className={`form__group ${touched.firstName && errors.firstName ? " form__group--error" : ""}`}>
                    <label className="form__label">{getTranslation(translations, t => t?.form.firstname, language)}</label>
                    <input
                      id="firstName"
                      className="form__input"
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.firstName && errors.firstName && <p className="form__error">{errors.firstName}</p>}
                  </div>
                  <div className={`form__group ${touched.lastName && errors.lastName ? " form__group--error" : ""}`}>
                    <label className="form__label">{getTranslation(translations, t => t?.form.lastname, language)}</label>
                    <input
                      id="lastName"
                      className="form__input"
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.lastName && errors.lastName && <p className="form__error">{errors.lastName}</p>}
                  </div>
                </div>
                <div className="form__row form__row--2col">
                  <div className={`form__group ${touched.email && errors.email ? " form__group--error" : ""}`}>
                    <label className="form__label">{getTranslation(translations, t => t?.form.email, language)}</label>
                    <input
                      id="email"
                      className="form__input"
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email && <p className="form__error">{errors.email}</p>}
                  </div>
                  <div className={`form__group ${touched.tel && errors.tel ? " form__group--error" : ""}`}>
                    <label className="form__label">{getTranslation(translations, t => t?.form.phone, language)}</label>
                    <input
                      id="tel"
                      className="form__input"
                      type="text"
                      name="tel"
                      value={values.tel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.tel && errors.tel && <p className="form__error">{errors.tel}</p>}
                  </div>
                </div>
                <div className="form__row">
                  <div className={`form__group ${touched.remarks && errors.remarks ? " form__group--error" : ""}`}>
                    <label className="form__label">{getTranslation(translations, t => t?.form.message, language)}</label>
                    <textarea
                      id="remarks"
                      className="form__textarea"
                      name="remarks"
                      value={values.remarks}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                    {touched.remarks && errors.remarks && <p className="form__error">{errors.remarks}</p>}
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__group">
                    <label className="checkbox__label">
                      <input
                        id="demo"
                        type="checkbox"
                        checked={values.demo}
                        className="form__checkbox"
                        name="demo"
                        onChange={e => {
                          handleChange(e)
                          handleTagsChange(e)
                        }}
                        value={demoTag}
                      />
                      <span>{getTranslation(translations, t => t?.form.demo, language)}</span>
                    </label>
                  </div>
                </div>
                <div className="form__row">
                  <div className={`form__group ${submitTouched && errors.privacy ? " form__group--error" : ""}`}>
                    <label className="checkbox__label">
                      <input
                        id="privacy"
                        type="checkbox"
                        checked={values.privacy}
                        className="form__checkbox"
                        name="privacy"
                        onChange={handleChange}
                      />
                      <span>
                        {getTranslation(translations, t => t?.form.privacystatement1, language)}{" "}
                        <a href={`/${disclaimerPath ?? ""}`} target="_blank" rel="noreferrer noopener">
                          {getTranslation(translations, t => t?.form.privacystatement2, language)}
                        </a>
                      </span>
                    </label>
                    {submitTouched && errors.privacy && <p className="form__error">{errors.privacy}</p>}
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__group">
                    <input type="submit" className="cta cta--primary" value={buttonText} />
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
        <div className="form-contact__sidebar">
          <div className="tile tile--darkbackground">
            <div className="tile__body">
              <div className="tile__title">
                <h3>{sidebarBlueTitle}</h3>
              </div>
              <div className="tile__text" dangerouslySetInnerHTML={{ __html: sidebarBlueDescription }}></div>
            </div>
          </div>
          <div className="tile tile--shadow">
            <div className="tile__body">
              <div className="tile__title">
                <h3>{sidebarShadowTitle}</h3>
              </div>
              <div className="tile__text" dangerouslySetInnerHTML={{ __html: sidebarShadowDescription }}></div>
            </div>
          </div>
        </div>
      </>
    </section>
  )
}

export default ContactForm
