import React from "react"

interface LogoTileProps {
  image: any
}

const LogoTile: React.FC<LogoTileProps> = props => {
  return (
    <div className="tile tile--shadow">
      <div className="tile__body tile__body--logo">
        <img src={props.image.url} alt="" />
      </div>
    </div>
  )
}

export default LogoTile
