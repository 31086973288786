import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

interface SEOProps {
  description?: string
  lang?: string
  title?: string
  keywords?: string
  image?: any
  path?: string
}

const SEO: React.FC<SEOProps> = ({ description, lang, title, keywords, image, path }) => {
  const ogImage =
    image ||
    "https://media.licdn.com/dms/image/D4E3DAQEaUhJZWO0mhA/image-scale_191_1128/0/1703758891511/tide_software_cover?e=1705060800&v=beta&t=zhN1xxwieC87bMX-pWtPnV9VfZob1zjLFkxIktH-yj8"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ?? ""}
      link={[{ rel: "canonical", href: path }]}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          name: "keywords",
          content: keywords,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:image",
          content: image,
        },
        {
          property: "og:url",
          content: path,
        },
        {
          property: "og:description",
          content: description,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: "en",
  description: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
