import React from "react"

interface ArticleTextProps {
  text: string
}

const ArticleText: React.FC<ArticleTextProps> = props => {
  return <div className="article__news" dangerouslySetInnerHTML={{ __html: props.text }}></div>
}

export default ArticleText
