import { TideApiContactRequest, TideApiCrmcontactRequest } from "../models/tide"
import { endpointContact, endpointCrmcontact } from "../constants/tide-endpoints"

export async function requestCrmcontact(request: TideApiCrmcontactRequest): Promise<Response> {
  const response = await fetch(endpointCrmcontact, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": "824ae793-e6cb-4773-ac40-2669bf18ee8d",
    },
    body: JSON.stringify(request),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response
}

export async function requestContact(request: TideApiContactRequest): Promise<Response> {
  const response = await fetch(endpointContact, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": "824ae793-e6cb-4773-ac40-2669bf18ee8d",
    },
    body: JSON.stringify(request),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response
}
