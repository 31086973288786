import React from "react"
import Tile from "../../shared/tile"

interface DiscoverServiceProps {
  img: any
  quote: string
  title: string
  tileData: any
}

const DiscoverService: React.FC<DiscoverServiceProps> = props => {
  return (
    <div className="discover discover--core">
      <div className="discover__container">
        <div className="discover__visual">
          <img src={props.img.url} alt="" />
          <div className="discover__quote" dangerouslySetInnerHTML={{ __html: props.quote }}></div>
        </div>
        <div className="discover__tiles tile-list tile-list--2col">
          <div className="discover__title">
            <div
              dangerouslySetInnerHTML={{
                __html: props.title
                  .replace(/<span style="text-decoration: underline;">/g, '<span class="underline underline--services">')
                  .replace(/<\/span>/g, "</span>"),
              }}
            ></div>
          </div>
          <div className="tile-list__tiles">
            {props.tileData.map((t, index) => (
              <Tile
                key={index}
                title={t.content.general.title}
                description={t.content.general.description}
                path={t.content.general.path}
                linkText={t.content.general.buttonText}
              ></Tile>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiscoverService
