import React from "react"

interface CtablockProps {
  title: string
  description?: string
  primaryButtonText: string
  primaryButtonLink: string
  buttonBetweenText?: string
  secondaryButtonText?: string
  secondaryButtonLink?: string
}

const Ctablock: React.FC<CtablockProps> = props => {
  return (
    <section>
      <div className="ctablock">
        <div
          dangerouslySetInnerHTML={{
            __html: props.title
              ?.toString()
              ?.replace(/<strong>/g, '<span class="bold">')
              .replace(/<\/strong>/g, "</span>"),
          }}
        ></div>
        {props.description && (
          <div
            dangerouslySetInnerHTML={{
              __html: props.description
                ?.toString()
                ?.replace(/<strong>/g, '<span class="bold">')
                .replace(/<\/strong>/g, "</span>"),
            }}
          ></div>
        )}
        <div className="ctablock__link">
          <a href={"/" + props.primaryButtonLink} className="cta cta--primary">
            <span>{props.primaryButtonText}</span>
          </a>
          {props.buttonBetweenText && props.buttonBetweenText}
          {props.secondaryButtonText && (
            <a href={props.secondaryButtonLink} className="cta cta--secondary">
              <span>{props.secondaryButtonText}</span>
            </a>
          )}
        </div>
      </div>
    </section>
  )
}

export default Ctablock
