import React from "react"

interface FormRowProps {
  label: string
  inputName: string
  required?: boolean
}

const FormRow: React.FC<FormRowProps> = props => {
  return (
    <div className="form__row">
      <div className="form__group">
        <label className="form__label">{props.label}</label>
        <input className="form__input" type="text" name={props.inputName} required={props.required} />
      </div>
    </div>
  )
}

export default FormRow
