import React from "react"
import ListItem from "../../shared/list-item"
import ImageListItem from "../../shared/list-item"
import DiagramTileInventoryRow from "../../components/diagram/diagram-tile-inventory-row"

interface DiagramTilesProps {
  headTitle: string
  lowerTitle: string
  listItems: any
  secondHeadTitle: string
  secondLowerTitle: string
  thirdLowerTitle: string
}
const DiagramTiles: React.FC<DiagramTilesProps> = props => {
  return (
    <div className="diagram-tiles diagram-tiles--rectanglebottom">
      <div className="tile-list tile-list--2col">
        <div className="tile-list__tiles">
          <div className="tile tile--background">
            <div className="tile__body">
              <div className="tile__title">
                <h3>{props.headTitle}</h3>
              </div>
              <div className="tile__text">
                <h4 className="title">
                  <span>{props.lowerTitle}</span>
                </h4>
                <div className="tile__list tile__list--2col">
                  <ul className="list">
                    {props.listItems.flatMap(l =>
                      l.splice(0, 6).map((i, index) => {
                        switch (i.templateName) {
                          case "ListItem":
                            return <ListItem key={index} title={i.content.general.title}></ListItem>
                        }
                      })
                    )}
                  </ul>
                  <ul className="list">
                    {props.listItems.flatMap(l =>
                      l.splice(0, 5).map((i, index) => {
                        switch (i.templateName) {
                          case "ListItem":
                            return <ListItem key={index} title={i.content.general.title}></ListItem>
                        }
                      })
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sign sign--plus">+</div>
          <div className="tile tile--background">
            <div className="tile__body">
              <div className="tile__title">
                <h3>{props.secondHeadTitle}</h3>
              </div>
              <div className="tile__text">
                <h4 className="title">
                  <span>{props.secondLowerTitle}</span>
                </h4>
                {props.listItems.flatMap(l =>
                  l.splice(0, 5).map((i, index) => {
                    switch (i.templateName) {
                      case "ImageListItem":
                        return (
                          <DiagramTileInventoryRow
                            key={index}
                            firstTitle={i.content.general.firstTitle}
                            secondTitle={i.content.general.secondTitle}
                            image={i.content.general.image}
                            icons={i.content.general.icons}
                            firstIcon={i.content.general.firstIcon}
                            secondIcon={i.content.general.secondIcon}
                            thirdIcon={i.content.general.thirdIcon}
                            fourthIcon={i.content.general.fourthIcon}
                            fifthIcon={i.content.general.fifthIcon}
                          ></DiagramTileInventoryRow>
                        )
                      default:
                        break
                    }
                  })
                )}
                <h4 className="title title--witharrow">
                  <span>{props.thirdLowerTitle}</span>
                </h4>
                <ul className="list list--logos">
                  {props.listItems.flatMap(l =>
                    l.map((i, index) => {
                      switch (i.templateName) {
                        case "ImageListItem":
                          return <ImageListItem key={index} image={i.content.general.image}></ImageListItem>
                        default:
                          break
                      }
                    })
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagramTiles
