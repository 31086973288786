import React from "react"
import TileListItem from "./tile-list-item"
import { buildClassName } from "../../utils/classNameBuilder"

interface TileListProps {
  title: string
  threeCol?: boolean
  fourCol?: boolean
  childItems: any
}

const TileList: React.FC<TileListProps> = props => {
  const className = buildClassName([
    "tile-list",
    props.threeCol && "tile-list--3col",
    props.fourCol && "tile-list--4col",
    "tile-list--bgtertiary",
  ])

  return (
    <section>
      <div className={className}>
        <div className="tile-list__container">
          <div className="tile-list__title">
            <div
              dangerouslySetInnerHTML={{
                __html: props.title
                  .replace(/<span style="text-decoration: underline;">/g, '<span class="underline">')
                  .replace(/<\/span>/g, "</span>"),
              }}
            ></div>
          </div>
          <div className="tile-list__tiles">
            {props.childItems.map(c => (
              <TileListItem
                key={c.id}
                icon={c.content.general.icon}
                tileTitle={c.content.general.title}
                tileDescription={c.content.general.description}
                buttonText={c.content.general.buttonText}
                path={c.content.general.path}
              ></TileListItem>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TileList
