import React from "react"

interface HeroProps {
  title: string
  description: string
  buttonText: string
  buttonLink: string
  image: any
  slogan: string
  symbol: any
  question: string
}

const Hero: React.FC<HeroProps> = props => {
  return (
    <div className="hero">
      <div className="hero__container">
        <div className="hero__copy">
          <div className="hero__title">
            <h1
              dangerouslySetInnerHTML={{
                __html: props.title
                  .toString()
                  .replace(/<strong>/g, '<span class="bold">')
                  .replace(/<\/strong>/g, "</span>"),
              }}
            ></h1>
          </div>
          <div className="hero__text" dangerouslySetInnerHTML={{ __html: props.description }}></div>
          <a href={"/" + props.buttonLink} className="cta cta--primary">
            <span>{props.buttonText}</span>
          </a>
        </div>
        <div className="hero__media">
          <div className="hero__media-symbol">
            <svg xmlns="http://www.w3.org/2000/svg" width="162.164" height="132.95" viewBox="0 0 162.164 132.95">
              <defs>
                <linearGradient id="hero-linear-gradient" x1="0.947" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                  <stop offset="0" stopColor="#8fefd2" />
                  <stop offset="1" stopColor="#2dbb90" />
                </linearGradient>
              </defs>
              <path
                id="Path_546"
                data-name="Path 546"
                d="M245.613,403.066s-58.881,21.412-58.435,89.213c0-.447-2.678,40.146,29.216,63.787.224-.892,26.542-10.705,31.9-34.347C248.289,522.166,245.613,403.066,245.613,403.066Z"
                transform="matrix(-0.53, 0.848, -0.848, -0.53, 603.144, 135.981)"
                fill="url(#hero-linear-gradient)"
              />
            </svg>
          </div>
          <img src={props.image?.url} alt="" />
        </div>
      </div>
      <div
        className="hero__slogan"
        dangerouslySetInnerHTML={{
          __html: props.slogan
            .toString()
            .replace(/<strong>/g, '<span class="bold">')
            .replace(/<\/strong>/g, "</span>"),
        }}
      ></div>
      <div className="hero__symbol">
        <div className="symbol">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 66">
            <defs>
              <linearGradient id="a" x1="1" y1="1" x2="0.019" y2="0.054" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#95c3e6" />
                <stop offset="1" stopColor="#2672ab" />
              </linearGradient>
              <linearGradient id="b" x1="0.013" y1="0.76" x2="1" y2="0.141" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#9494f3" />
                <stop offset="1" stopColor="#2626c4" />
              </linearGradient>
              <linearGradient id="c" x1="0.947" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#8fefd2" />
                <stop offset="1" stopColor="#2dbb90" />
              </linearGradient>
            </defs>
            <g transform="translate(-187.122 -394.705)">
              <path
                d="M291,432.375s4.224-21.151-15.805-33.138c.131.08-11.334-7.963-23.99-2.8.222.226-1.594,9.723,4.406,15.524-.132-.08,35.39,20.419,35.39,20.419"
                transform="translate(-39.813 0)"
                fill="url(#a)"
              />
              <path
                d="M271.63,497.449c-.3.088-7.789-6.034-15.747-3.523.133-.077-34.815,21.385-34.815,21.385s16.583,13.792,36.666,1.894c-.133.077,12.4-6.17,13.9-19.755"
                transform="translate(-21.121 -61.358)"
                fill="url(#b)"
              />
              <path
                d="M207.315,403.066s-20.327,7.392-20.173,30.8c0-.154-.924,13.859,10.086,22.021.077-.308,9.163-3.7,11.011-11.857C208.239,444.182,207.315,403.066,207.315,403.066Z"
                transform="translate(0 -5.202)"
                fill="url(#c)"
              />
            </g>
          </svg>
        </div>
      </div>
      <div
        className="hero__question"
        dangerouslySetInnerHTML={{
          __html: props.question
            .toString()
            .replace(/<strong>/g, '<span class="bold">')
            .replace(/<\/strong>/g, "</span>"),
        }}
      ></div>
    </div>
  )
}

export default Hero
