import React from "react"

interface BackgroundTileProps {
  title: string
  description: string
  link: string
  buttonText: string
}

const BackgroundTile: React.FC<BackgroundTileProps> = props => {
  return (
    <div className="tile tile--background">
      <div className="tile__body">
        <div className="tile__title">
          <div dangerouslySetInnerHTML={{ __html: props.title }}></div>
        </div>
        <div className="tile__text">
          <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </div>
        {props.buttonText && (
          <a href={"/" + props.link} className="link link--witharrow">
            <span>{props.buttonText}</span>
          </a>
        )}
      </div>
    </div>
  )
}

export default BackgroundTile
