import React from "react"
import BackgroundTile from "./background-tile"
import ShadowTile from "./shadow-tile"
import icons from "../../images/tide-commercial_icons.svg"

interface DiagramPimProps {
  title: string
  description: string
  tileTitle: string
  shadowTileData: any
  backgroundTileData: any
  backgroundFullTileData: any
}

const DiagramPim: React.FC<DiagramPimProps> = props => {
  return (
    <div className="titlewithtiles">
      <div className="titlewithtiles__title">
        <div dangerouslySetInnerHTML={{ __html: props.title }}></div>
        <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
      </div>
      <div className="titlewithtiles__tiles">
        <div className="diagram-tiles diagram-tiles--rectanglebottom">
          <div className="tile-list tile-list--3col">
            <div className="tile-list__tiles">
              {props.backgroundTileData.map((b, index) => (
                <BackgroundTile
                  key={index}
                  title={b.content.general.title}
                  description={b.content.general.description}
                  link={b.content.general.link}
                  buttonText={b.content.general.buttonText}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="diagram-tiles diagram-tiles--linestopbottom">
          <div className="diagram__title">
            <svg id="logo" viewBox="0 0 197 57">
              <defs>
                <style>{`.cls-1{isolation:isolate;}.cls-2{fill:url(#linear-gradient);}.cls-3{fill:url(#linear-gradient-2);}.cls-4{fill:url(#linear-gradient-3);}`}</style>
                <linearGradient
                  id="linear-gradient"
                  x1="-198.04"
                  y1="413.11"
                  x2="-199.02"
                  y2="414.05"
                  gradientTransform="matrix(34.67, 0, 0, -32.44, 6922.49, 13430.92)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#95c3e6" />
                  <stop offset="1" stopColor="#2672ab" />
                </linearGradient>
                <linearGradient
                  id="linear-gradient-2"
                  x1="-200.23"
                  y1="409.83"
                  x2="-199.24"
                  y2="410.45"
                  gradientTransform="matrix(43.54, 0, 0, -24.73, 8731.14, 10188.94)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#9494f3" />
                  <stop offset="1" stopColor="#2626c4" />
                </linearGradient>
                <linearGradient
                  id="linear-gradient-3"
                  x1="-192.91"
                  y1="417.23"
                  x2="-193.36"
                  y2="416.23"
                  gradientTransform="matrix(18.18, 0, 0, -45.48, 3522.45, 18975.52)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stopColor="#8fefd2" />
                  <stop offset="1" stopColor="#2dbb90" />
                </linearGradient>
              </defs>
              <g className="cls-1">
                <g className="cls-1">
                  <path d="M89,53.91V6.75H74.37V2.36h33.84V6.75H93.6V53.91Z" />
                  <path d="M114.91,9.48a3.47,3.47,0,0,1-2.56-1,3.37,3.37,0,0,1-1-2.48,3.55,3.55,0,0,1,3.6-3.6,3.37,3.37,0,0,1,2.48,1,3.48,3.48,0,0,1,1,2.56,3.38,3.38,0,0,1-1,2.48A3.37,3.37,0,0,1,114.91,9.48ZM112.6,53.91V18.63h4.61V53.91Z" />
                  <path d="M153.93,47.72h-.29a17.13,17.13,0,0,1-2.08,2.77,13.28,13.28,0,0,1-2.88,2.34,16,16,0,0,1-3.75,1.62,16.64,16.64,0,0,1-4.61.61,15.09,15.09,0,0,1-6.4-1.4,16.76,16.76,0,0,1-5.3-3.89,18.81,18.81,0,0,1-3.6-5.94,20.85,20.85,0,0,1-1.33-7.56A20.78,20.78,0,0,1,125,28.71a18.59,18.59,0,0,1,3.6-5.94,16.61,16.61,0,0,1,5.3-3.89,16.38,16.38,0,0,1,14.76.76,14.79,14.79,0,0,1,5,5.11h.29l-.29-4.9V2.36h4.61V53.91h-4.32Zm-12.81,3.16a12.51,12.51,0,0,0,4.86-1,11.76,11.76,0,0,0,4.1-2.88,14.29,14.29,0,0,0,2.81-4.61,17.09,17.09,0,0,0,1-6.15,17.35,17.35,0,0,0-1-6.19,13.77,13.77,0,0,0-2.81-4.61,11.94,11.94,0,0,0-4.1-2.85,12.7,12.7,0,0,0-4.86-1,12.2,12.2,0,0,0-4.86,1,11.85,11.85,0,0,0-4.11,2.92,14.41,14.41,0,0,0-2.81,4.6,16.91,16.91,0,0,0-1,6.09,17.23,17.23,0,0,0,1,6.12A14,14,0,0,0,132.15,47a12.43,12.43,0,0,0,4.11,2.88A12.19,12.19,0,0,0,141.12,50.88Z" />
                  <path d="M197.35,45.56A18.13,18.13,0,0,1,195,49.23a16.11,16.11,0,0,1-3.35,3,15.87,15.87,0,0,1-4.35,2,18.07,18.07,0,0,1-5.4.76,17.65,17.65,0,0,1-7.06-1.4,16.51,16.51,0,0,1-5.58-3.93,17.91,17.91,0,0,1-3.63-5.94,20.94,20.94,0,0,1-1.3-7.52A21.69,21.69,0,0,1,165.49,29a17.78,17.78,0,0,1,3.42-6,16.2,16.2,0,0,1,12.53-5.58,17,17,0,0,1,6.87,1.33,14.88,14.88,0,0,1,5.22,3.74,17,17,0,0,1,3.35,5.8,22.5,22.5,0,0,1,1.19,7.49v.43a1.08,1.08,0,0,0-.07.36v.43H169a15.21,15.21,0,0,0,1.3,6.26,13.28,13.28,0,0,0,3.09,4.29,12,12,0,0,0,4.18,2.48,13.59,13.59,0,0,0,4.46.79,11.07,11.07,0,0,0,7.06-2.05,17,17,0,0,0,4.32-5.22Zm-4.11-12.39a14.36,14.36,0,0,0-.72-3.74,11.86,11.86,0,0,0-2-3.75,10.82,10.82,0,0,0-3.64-2.88,12.32,12.32,0,0,0-5.58-1.15,11.72,11.72,0,0,0-4.18.76,11.44,11.44,0,0,0-3.63,2.19,12.42,12.42,0,0,0-2.74,3.6,15,15,0,0,0-1.47,5Z" />
                </g>
              </g>
              <path
                className="cls-2"
                d="M55.16,32.44S58.8,14.22,41.55,3.9c.12.07-9.76-6.85-20.65-2.41.19.19-1.38,8.37,3.79,13.37L55.16,32.44"
              />
              <path
                className="cls-3"
                d="M54.57,35.64c-.26.07-6.7-5.2-13.56-3C41.13,32.54,11,51,11,51s14.28,11.87,31.57,1.63c-.12.06,10.68-5.32,12-17"
              />
              <path
                className="cls-4"
                d="M17.39,2.72S-.11,9.08,0,29.24c0-.13-.8,11.93,8.68,19C8.77,47.93,16.59,45,18.18,38,18.18,38.12,17.39,2.72,17.39,2.72Z"
              />
            </svg>
            {props.tileTitle}
          </div>
        </div>
        <div className="diagram-tiles">
          <div className="tile-list tile-list--full">
            <div className="tile-list__tiles">
              {props.backgroundFullTileData?.content && (
                <BackgroundTile
                  title={props.backgroundFullTileData?.content?.general?.title}
                  description={props.backgroundFullTileData?.content?.general?.description}
                  link={""}
                  buttonText={""}
                />
              )}
            </div>
          </div>
          <div className="tile-list tile-list--4col">
            <div className="tile-list__tiles">
              {props.shadowTileData.map((s, index) => (
                <ShadowTile
                  key={index}
                  title={s.content?.general?.title}
                  icon={s.content?.general?.icon}
                  description={s.content?.general?.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagramPim
