import React from "react"

interface TitleWithTextProps {
  title: string
  description: string
  buttonText: string
  buttonLink: string
}

const titleWithText: React.FC<TitleWithTextProps> = props => {
  return (
    <div className="titlewithtext">
      <div
        className="titlewithtext__title"
        dangerouslySetInnerHTML={{
          __html: props.title
            .toString()
            .replace(/<strong>/g, '<span class="bold">')
            .replace(/<\/strong>/g, "</span>"),
        }}
      ></div>
      <div className="titlewithtext__copy">
        <div className="titlewithtext__copy" dangerouslySetInnerHTML={{ __html: props.description.toString() }}></div>
        {props.buttonText && (
          <a href={"/" + props.buttonLink} className="cta cta--primary">
            {props.buttonText}
          </a>
        )}
      </div>
    </div>
  )
}

export default titleWithText
