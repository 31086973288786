import React from "react"
import { useForm } from "@formspree/react"
import FormRow from "../../shared/form-row"

interface FormContactProps {
  title: string
  description: string
  items: any
}

const FormContact: React.FC<FormContactProps> = props => {
  const [state, handleSubmit] = useForm("xnqlbdoy")

  return (
    <section className="form-contact">
      {
        <>
          <div className="form-contact__text">
            <h2
              dangerouslySetInnerHTML={{
                __html: props.title
                  .toString()
                  .replace(/<strong>/g, '<span class="bold">')
                  .replace(/<\/strong>/g, "</span>"),
              }}
            ></h2>
            <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
            <div className="form-contact__form">
              {!state.succeeded ? (
                <form onSubmit={handleSubmit} method="POST" className="form form--contact">
                  {props.items.map((t, index) => (
                    <FormRow
                      key={index}
                      label={t.content.general.labelText}
                      inputName={t.content.general.inputName}
                      required={t.content.general.required}
                    ></FormRow>
                  ))}
                  <div className="form__row">
                    <label className="checkbox__label">
                      <input type="checkbox" className="form__checkbox" name="privacyAgreement" />
                      By sending my message I agree to the{" "}
                      <a href="" target="_blank" rel="noreferrer">
                        privacy statement of Tide.
                      </a>
                    </label>
                  </div>
                  <div className="form__row">
                    <div className="form__group">
                      <button type="submit" className="cta cta--primary">
                        <span>Send</span>
                      </button>
                    </div>
                  </div>
                  <input type="hidden" name="_next" value="https://mysite.com/thanks" />
                </form>
              ) : (
                <p>Thank you for your message. We will contact you as soon as possible!</p>
              )}
            </div>
          </div>
          <div className="form-contact__sidebar">
            <div className="tile tile--darkbackground">
              <div className="tile__body">
                <div className="tile__title">
                  <h3>Reach out directly</h3>
                </div>
                <div className="tile__text">
                  <p>
                    Call us at <a href="tel:+32 9 336 22 99">+32 9 336 22 99</a>
                    <br />
                    or email <a href="">info@qite.be</a>
                  </p>
                  <p>
                    <a
                      href="https://www.google.com/maps/place/Overzet+16a,+9000+Gent/@51.0521416,3.6960452,17z/data=!3m1!4b1!4m5!3m4!1s0x47c371716dff0a59:0xcdb614b26a0f2569!8m2!3d51.0521382!4d3.6982339"
                      target="blank"
                      className="link link--hover-underline"
                    >
                      Tide BV
                      <br />
                      Overzet 16 A<br />
                      9000 Gent
                      <br />
                      Belgium
                    </a>
                    <br />
                    VAT BE0502.988.154
                  </p>
                </div>
              </div>
            </div>
            <div className="tile tile--shadow">
              <div className="tile__body">
                <div className="tile__title">
                  <h3>Tide demo</h3>
                </div>
                <div className="tile__text">
                  <p>
                    Schedule a free 1-on-1 live demo of the Tide platform and one of our experts will show you all features that will help
                    you digitalize.
                  </p>
                  {/* <a href="" className="cta cta--secondary">
                    <span>Schedule a demo</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </section>
  )
}

export default FormContact
