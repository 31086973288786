import React from "react"
import ServicesDiagramItem from "./services-diagram-item"

interface ServicesDiagramProps {
  innerTitle: string
  innerDescription: string
  diagramTitle: string
  diagramDescription: string
  servicesDiagramItems: any
  buttonTitle: string
  buttonLink: string
}

const ServicesDiagram: React.FC<ServicesDiagramProps> = props => {
  return (
    <div className="services-diagram">
      <div className="services-diagram__container">
        <div className="services-diagram__services">
          <div className="services-diagram__icons">
            <ul>
              {props.servicesDiagramItems.map((s, index) => (
                <ServicesDiagramItem
                  key={index}
                  title={s.content.general.title}
                  icon={s.content.general.icon}
                  right={s.content.general.textRight}
                  left={s.content.general.textLeft}
                  bottom={s.content.general.textBottom}
                ></ServicesDiagramItem>
              ))}
            </ul>
          </div>

          <div className="services-diagram__inner-text">
            <div
              dangerouslySetInnerHTML={{
                __html: props.innerTitle
                  .toString()
                  .replace(/<strong>/g, '<span class="bold">')
                  .replace(/<\/strong>/g, "</span>"),
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: props.innerDescription
                  .toString()
                  .replace(/<strong>/g, '<span class="bold">')
                  .replace(/<\/strong>/g, "</span>"),
              }}
            ></div>
          </div>
        </div>

        <div className="services-diagram__text">
          <div
            className="services-diagram__title"
            dangerouslySetInnerHTML={{
              __html: props.diagramTitle
                .toString()
                .replace(/<strong>/g, '<span class="bold">')
                .replace(/<\/strong>/g, "</span>"),
            }}
          ></div>
          <div
            className="services-diagram__copy"
            dangerouslySetInnerHTML={{
              __html: props.diagramDescription
                .toString()
                .replace(/<strong>/g, '<span class="bold">')
                .replace(/<\/strong>/g, "</span>"),
            }}
          ></div>
          {props.buttonTitle && (
            <div className="feature__cta">
              <a href={"/" + props.buttonLink} className="cta cta--secondary">
                <span>{props.buttonTitle}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ServicesDiagram
