import React from "react"
import icons from "../../images/tide-commercial_icons.svg"

interface TileProps {
  icon: string
  title: string
  text: string
}

const Tile: React.FC<TileProps> = props => {
  return (
    <div className="tile">
      <div className="tile__body">
        <div className="tile__title tile__title--withicon">
          <svg className="icon icon--tile-window">
            <use href={`${icons}#${props.icon}`}></use>
          </svg>
          <div dangerouslySetInnerHTML={{ __html: props.title }}></div>
        </div>
        <div className="tile__text">
          <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
        </div>
      </div>
    </div>
  )
}

export default Tile
