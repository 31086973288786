import React from "react"
import IntegrationsItem from "./integrations-item"

interface IntegrationsProps {
  label: string
  title: string
  description: string
  buttonTitle: string
  buttonLink: string
  integrationItems: any
}

const integrations: React.FC<IntegrationsProps> = props => {
  return (
    <div className="integrations">
      <div className="integrations__container">
        <div className="integrations__visual">
          <div className="integrations__visual-col1">
            {props.integrationItems
              .map((i, index) => <IntegrationsItem key={index} image={i.content.general.image.url}></IntegrationsItem>)
              .splice(0, 4)}
          </div>
          <div className="integrations__visual-col2">
            {props.integrationItems
              .map((i, index) => <IntegrationsItem key={index} image={i.content.general.image.url}></IntegrationsItem>)
              .splice(4, 8)}
          </div>
        </div>
        <div className="integrations__text">
          <div className="integrations__label">{props.label}</div>
          <div className="integrations__title" dangerouslySetInnerHTML={{ __html: props.title.toString() }}></div>
          <div className="integrations__copy" dangerouslySetInnerHTML={{ __html: props.description.toString() }}></div>
          <div className="integrations__cta">
            <a href={"/" + props.buttonLink} className="cta cta--secondary">
              <span>{props.buttonTitle}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default integrations
