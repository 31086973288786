import React from "react"
import Tile from "./tile"

interface TileOverviewProps {
  tileDate: any
}

const TileOverview: React.FC<TileOverviewProps> = props => {
  const sortedTiles = [...props.tileDate].sort((a, b) =>
    a.content.general.isHighlighted === b.content.general.isHighlighted
      ? 0
      : a.content.general.isHighlighted
        ? -1
        : 1
  );
  return (
    <div>
      <div className="tile__grid--container">
        <div className="tile__grid tile-slider">
          {sortedTiles.map((item, index) => (
            <Tile
              key={index}
              title={item.content.general.title}
              description={item.content.general.description}
              image={item.content.general.image}
              buttonText={item.content.general.buttonText}
              path={item.content.general.path}
              isFirst={index === 0}
              isHighlighted={item.content.general.isHighlighted}
            ></Tile>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TileOverview
