import { get } from "lodash"

const firstNameKey = "firstName"
const lastNameKey = "lastName"
const emailKey = "email"
const phoneKey = "phone"
const messageKey = "message"
const infoSessionTitleKey = "infoSessionTitle"
const infoSessionDateKey = "infoSessionDate"
const infoSessionPlaceKey = "infoSessionPlace"
const demoKey = "demo"

const placeholders = [
  firstNameKey,
  lastNameKey,
  emailKey,
  phoneKey,
  messageKey,
  infoSessionTitleKey,
  infoSessionDateKey,
  infoSessionPlaceKey,
  demoKey,
]

export interface ProcessPlaceholdersConfig {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  message?: string
  demo?: string
  infoSessionTitle?: string
  infoSessionDate?: string
  infoSessionPlace?: string
}

export default function (content: string, config: ProcessPlaceholdersConfig): string {
  for (const placeholder of placeholders) {
    content = replace(`{{${placeholder}}}`, content, get(config, placeholder))
  }
  return content
}

function replace(regexKey: string, content: string, value: string): string {
  const regex = new RegExp(regexKey, "gi")
  return content.replace(regex, value)
}
