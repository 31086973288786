import React from "react"

interface TileProps {
  title: string
  description: string
}

const Tile: React.FC<TileProps> = props => {
  return (
    <div className="tile tile--shadow">
      <div className="tile__body">
        <div className="tile__title" dangerouslySetInnerHTML={{ __html: props.title }}></div>
        <div className="tile__text">
          <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </div>
      </div>
    </div>
  )
}

export default Tile
