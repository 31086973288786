import React, { useRef } from "react"
import Carousel from "react-elastic-carousel"
import { setImageParameters } from "../../utils/imageResize"

interface ClientsProps {
  items: any
}

const Clients: React.FC<ClientsProps> = props => {
  const carouselRef = useRef(null)

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 992, itemsToShow: 2 },
    { width: 1200, itemsToShow: 5 },
  ]

  return (
    <div className="clients">
      <Carousel ref={carouselRef} showArrows={false} itemPadding={[0, 0, 0, 0]} breakPoints={breakPoints}>
        {props.items.map((i, index) => (
          <div key={index} className="clients__item">
            <img
              src={setImageParameters(i.content.general.image.url, { width: 268, height: 66, quality: 100, mode: "boxpad" })}
              alt={i.content.general.image.altText}
            />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default Clients
