import React from "react"

interface IntegrationsItemProps {
  image: any
}

const IntegrationsItem: React.FC<IntegrationsItemProps> = props => {
  return (
    <div className="integrations__item">
      <img src={props.image} alt="" />
    </div>
  )
}

export default IntegrationsItem
