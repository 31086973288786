import React from "react"
import Tile from "./tile"
import LogoTile from "./logo-tile"
import { buildClassName } from "../../utils/classNameBuilder"

interface TitleWithTilesProps {
  align?: boolean
  title: string
  description: string
  reverse?: boolean
  image: any
  tileDate: any
}

const TitleWithTiles: React.FC<TitleWithTilesProps> = props => {
  const className = buildClassName([
    buildClassName(["titlewithtiles", props.align && "titlewithtiles--verticalalign"]),
    props.reverse && "titlewithtiles--reverse",
  ])

  return (
    <div className={className}>
      <div className="titlewithtiles__title">
        <div
          dangerouslySetInnerHTML={{
            __html: props.title
              .toString()
              .replace(/<strong>/g, '<span class="bold">')
              .replace(/<\/strong>/g, "</span>"),
          }}
        ></div>
        <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
      </div>
      <div className="titlewithtiles__tiles tile-list tile-list--2col">
        <div className="tile-list__tiles">
          {props.tileDate.map((t, index) => {
            switch (t.templateName) {
              case "TileItem":
                return <Tile key={index} title={t.content.general.title} description={t.content.general.description}></Tile>
              default:
                break
            }
          })}
        </div>
        <div className="tile-list__tiles tile-list__tiles--logos">
          {props.tileDate.map((t, index) => {
            switch (t.templateName) {
              case "TileLogoItem":
                return <LogoTile key={index} image={t.content.general.image}></LogoTile>
              default:
                break
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default TitleWithTiles
