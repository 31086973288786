import React from "react"
import icons from "../../images/tide-commercial_icons.svg"

interface DiagramTileInventoryRowProps {
  firstTitle?: string
  secondTitle?: string
  image?: any
  icons: boolean
  firstIcon: string
  secondIcon: string
  thirdIcon: string
  fourthIcon: string
  fifthIcon: string
}

const DiagramTileInventoryRow: React.FC<DiagramTileInventoryRowProps> = props => {
  return props.icons ? (
    <div className="tile__inventory-row tile__inventory-row--icons">
      <div className="tile__inventory-item">
        <h4 className="title">
          <span>{props.firstTitle}</span>
        </h4>
      </div>
      <div className="tile__inventory-item">
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.firstIcon}`}></use>
        </svg>
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.secondIcon}`}></use>
        </svg>
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.thirdIcon}`}></use>
        </svg>
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.fourthIcon}`}></use>
        </svg>
        <svg className="icon icon--tile-window">
          <use href={`${icons}#${props.fifthIcon}`}></use>
        </svg>
      </div>
    </div>
  ) : (
    <div className="tile__inventory-row">
      <div className="tile__inventory-item">
        <h4 className="title">
          <span>{props.firstTitle}</span>
        </h4>
      </div>
      <div className="tile__inventory-item">{props.secondTitle}</div>
      <div className="tile__inventory-item">
        <img src={props.image.url} alt="" />
      </div>
      <div className="tile__inventory-item">· · ·</div>
    </div>
  )
}

export default DiagramTileInventoryRow
