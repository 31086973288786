import React from "react"
import ListItemPath from "../../shared/list-item"

interface BreadCrumbsProps {
  items: any
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = props => {
  return (
    <ul className="breadcrumbs">
      {props.items.map((i, index) => (
        <ListItemPath key={index} path={i.content.general.path} text={i.content.general.title}></ListItemPath>
      ))}
    </ul>
  )
}

export default BreadCrumbs
