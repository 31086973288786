import React from "react"
import Tile from "./tile"

interface TileImageListProps {
  title: string
  description: string
  childItems: any
}

const TileImageList: React.FC<TileImageListProps> = props => {
  return (
    <section>
      <div className="tile-list tile-list--3col tile-list--bgsecondary">
        <div className="tile-list__container">
          <div className="tile-list__title">
            <div
              dangerouslySetInnerHTML={{
                __html: props.title
                  .replace(/<span style="text-decoration: underline;">/g, '<span class="underline">')
                  .replace(/<\/span>/g, "</span>"),
              }}
            ></div>
          </div>
          <div className="tile-list__description">
            <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
          </div>
          <div className="tile-list__tiles tile-list__tiles--withimage tile-list__tiles--largegutter">
            {props.childItems.map((c, index) => (
              <Tile
                key={index}
                title={c.content.general.title}
                description={c.content.general.description}
                link={c.content.general.link}
                path={c.content.general.path}
                image={c.content.general.image.url}
              ></Tile>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TileImageList
