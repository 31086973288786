import React from "react"

interface TileProps {
  title?: string
  description?: string
  path?: string
  linkText?: string
}

const Tile: React.FC<TileProps> = props => {
  return (
    <>
      {props.path && props.linkText && (
        <div className="tile tile--background">
          <div className="tile__body">
            <div className="tile__title">
              <div dangerouslySetInnerHTML={{ __html: props.title }}></div>
            </div>
            <div className="tile__text">
              <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
            </div>
            <a href={props.path} className="link link--witharrow">
              <span>{props.linkText}</span>
            </a>
          </div>
        </div>
      )}
      {!props.path && (
        <div className="tile tile--background">
          <div className="tile__body">
            <div className="tile__title">
              <div dangerouslySetInnerHTML={{ __html: props.title }}></div>
            </div>
            <div className="tile__text">
              <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Tile
